<template>
    <fieldset class="trip-settings trip-settings__fieldset trip-settings__fieldset_selects">
        <template>
            <restaurants-filter v-if="showRestaurantsFilter" />
        </template>
    </fieldset>
</template>

<script>
    import { mapMutations, mapState } from "vuex";
    import DaterangePicker from "@/components/DaterangePicker";
    import RestaurantsFilter from "@/components/RestaurantsFilter";

    export default {
        name: "TripSettings",
        components: {
            DaterangePicker,
            RestaurantsFilter,
        },
        props: {
            showRestaurantsFilter: {
                type: Boolean,
                default: true,
            }
        },
        computed: {
            ...mapState({
                reportDaterange: state => state.report.daterange,
                compareDaterange: state => state.load.compareDaterange,
            }),
            reportDaterangeModel: {
                get() {
                    return this.reportDaterange;
                },
                set(value) {
                    this.setReportParameter({ parameter: "daterange", value });
                }
            },
            compareDaterangeModel: {
                get() {
                    return this.compareDaterange;
                },
                set(value) {
                    this.setLoadParameter({ parameter: "compareDaterange", value });
                }
            },
        },
        methods: {
            ...mapMutations([
                "setReportParameter",
                "setLoadParameter",
            ]),
        }
    };
</script>

<style lang="scss">
    .trip-settings {
        margin-bottom: 15px;
    }
    .trip-settings__fieldset {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 10px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: start;

        @include desktop {
            grid-auto-flow: column;
        }

        &_dateranges {
            margin-bottom: 15px;
        }
    }
    .trip-settings__select {
        min-width: 240px;
        height: 40px;
        padding: 4px 8px;
        border-color: $gray-line;
        border-radius: 4px;

        &_multiple {
            padding: 0;

            & .multiselect__tags-wrap {
                display: none !important;
            }
        }
    }
    .trip-settings__button {
        place-self: center;
        padding: 5px 10px;
        border-radius: 4px;
        color: #fff;

        &_select {
            background-color: $green-primary;
        }

        &_clear {
            background-color: $red-secondary;
        }
    }
</style>
